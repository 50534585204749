import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import BlogFeaturedImage from "../components/blog/BlogFeaturedImage"
import Badges from "../components/badges"
import { CATEGORIES } from "../constants/categories"
import { INDUSTRIES } from "../constants/industries"
import RelatedBlogPosts from "../components/RelatedBlogPosts"
import Seo from "../components/Seo"

const badgeMap = { ...CATEGORIES, ...INDUSTRIES }
const PostTemplate = ({
  data: {
    wpPost: { id, title, content, author, featuredImage, date, tags, categories, seo },
  },
  location,
  pageContext,
}) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const customCrumbLabel = location.pathname.toLowerCase().replace("-", " ")

  const postDate = new Date(date).toDateString()

  const badgeList = [...categories.nodes.map((category) => category.name), ...tags.nodes.map((tag) => tag.name)]

  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} uri={seo.canonical} socialImage={featuredImage} />
      <Breadcrumb crumbs={crumbs} crumbSeparator=" / " crumbLabel={customCrumbLabel} />
      <div className="container blog-post">
        <div className="blog-post__header">
          <div className="blog-post__header__badges">
            <Badges badgeList={badgeList} badgeMap={badgeMap} />
          </div>
          <BlogFeaturedImage featuredImage={featuredImage} />
          <h1>{title}</h1>
        </div>
        <div className="blog-post__content">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
      <RelatedBlogPosts id={id} categories={categories} tags={tags} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    wpPost(id: { eq: $id }) {
      title
      content
      date
      id
      author {
        node {
          name
        }
      }
      featuredImage {
        node {
          altText
          sourceUrl
        }
      }
      tags {
        nodes {
          name
        }
      }
      categories {
        nodes {
          name
        }
      }
      seo {
        canonical
        metaDesc
        title
      }
    }
  }
`

export default PostTemplate
